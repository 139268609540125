import React from "react";
import { Link } from "gatsby";
import * as styles from "./collectionName.module.css";
import verified_logo from "../images/icon_verified.png";

export default class CollectionName extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        if (this.props.displayOnly === true) {
            return (
                <span className={styles.container} title={`${this.props.name}`}>
                    <span className={styles.at}></span>
                    <span>{this.props.name}</span>
                    {this.props.verified && <img src={verified_logo} className={styles.verified_icon} />}
                </span>
            );
        } else if (this.props.openLinksInNewTab === true) {
            return (
                <a className={`${styles.container} ${styles.link}`} target={"_blank"} href={`/c/${this.props.id}`} title={`${this.props.name}`}>
                    <span className={styles.at}></span>
                    <span>{this.props.name}</span>
                    {this.props.verified && <img src={verified_logo} className={styles.verified_icon} />}
                </a>
            );
        } else {
            return (
                <Link className={`${styles.container} ${styles.link}`} to={`/c/${this.props.id}`} title={`${this.props.name}`}>
                    <span className={styles.at}></span>
                    <span>{this.props.name}</span>
                    {this.props.verified && <img src={verified_logo} className={styles.verified_icon} />}
                </Link>
            );
        }
    }
}
