import React from "react";
import { Link, navigate } from "gatsby";
import Web3Utils from "web3-utils";
import InfiniteScroll from "react-infinite-scroll-component";
import * as styles from "./index.module.css";
import { withAuthentication } from "../../hoc/withAuthentication";
import Util from "../../config/util";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import Main from "../../components/main";
import ContentArea from "../../components/contentArea";
import TextArea from "../../components/textArea";
import Button from "../../components/button";
import Avatar from "../../components/avatar";
import MediaHistoryItem from "../../components/mediaHistoryItem";
import MissingDataPanel from "../../components/missingDataPanel";
import DropdownMenu from "../../components/dropdownMenu";
import AuctionCountdown from "../../components/auctionCountdown";
import ScreenLoad from "../../components/screenLoad";
import ValueRow from "../../components/valueRow";
import ListingItem from "../../components/listingItem";
import Checkbox from "../../components/checkbox";
import NotFoundPage from "../../components/notFoundPage";
import SearchLink from "../../components/searchLink";
import DisplayName from "../../components/displayname";
import CollectionName from "../../components/collectionName";
import SocialButton from "../../components/socialButton";
import LinkWithIcon from "../../components/linkWithIcon";
import OverlayContainer from "../../components/overlayContainer";
import OverlayInfo from "../../components/overlayInfo";
import BidOverlay from "../../components/bidOverlay";
import ReportOverlay from "../../components/reportOverlay";
import MediaAttribute from "../../components/mediaAttribute";
import Input from "../../components/input";
import Consts from "../../config/consts";
import Common from "../../config/common";
import Api from "../../config/api";
import Provider from "../../config/provider";
import { faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
    faPencilAlt,
    faEye,
    faEyeSlash,
    faGears,
    faArrowRight,
    faSync,
    faTag,
    faArrowUpRightFromSquare,
    faEllipsisH,
    faUser,
    faPlus,
    faMinus,
    faLink,
    faCheck,
    faClock,
    faCoins,
    faHeart as faHeartSolid,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";


export default withAuthentication(
    {
        requiredAuthLevels: [Consts.AUTH_LEVELS.AUTHENTICATED, Consts.AUTH_LEVELS.UNAUTHENTICATED],
        redirectPage: "/",
    },
    class Media extends React.Component {
        constructor(props) {
            super(props);
            this.main_ref = React.createRef();

            // Grab the query params from the url
            //const params = new URLSearchParams(props.location.search);
            this.state = {
                initialising: true,
                addingToCollection: false,
                changingState: false,
                fullscreen: false,
                user: props.auth.user || null,
                mediaId: props.params.media_id,
                content: null,
                isLiked: false,
                likeCount: 0,
                isRefreshingMetadata: false,

                history: [],
                historyFetching: false,
                historyHasMore: true,

                owners: [],
                ownersFetching: false,
                myListing: null,

                token: null,

                hasFetchedCollections: false,
                collectionsNotAddedTo: [],
                collectionsAddedTo: [],

                showBidOverlay: false,
                showCollectionOverlay: false,
                showOwnersOverlay: false,
                showReportOverlay: false,
                showDeleteOverlay: false,

                isBidding: false,
                hasConfirmedBidAcceptance: false,

                showSetupMintButton: false,
                showMintButton: false,
                showListButton: false,
                showBuyFixedButton: false,
                showBidAuctionButton: false,

                showEditMediaLink: false,
                showRemoveFromCollectionLink: false,
                showAddToCollectionLink: false,
                showEditMintOptionsLink: false,
                showEditListingLink: false,
                showRefreshMetadataLink: false,
                showViewMetadataLink: false,
                showHideMediaLink: false,
                showUnhideMediaLink: false,
                showViewOwnersLink: false,
                showDeleteLink: false,

                hasConfirmedDelete: false,
                deleting: false,
            };
        }

        componentDidMount() {
            // Get the media record first
            Api.market({
                endpoint: "/content/media",
                method: "GET",
                data: {
                    mediaId: this.props.params.media_id,
                },
            })
                .then((res) => {
                    // Check if we can find any listings belonging to the currently authenticated user
                    let my_listing = null;
                    if (this.props.auth.user) {
                        for (let i = 0; i < res.data.listings.length; i++) {
                            if (Common.isDisplayNameMine(this.props.auth.user, res.data.listings[i].owner.displayName)) {
                                my_listing = res.data.listings[i];
                                break;
                            }
                        }
                    }
                    console.log("--- res.data", res.data)
                    // Update the state
                    this.setState(
                        {
                            content: res.data,
                            likeCount: Common.objectPropertyExists(res.data, "likeCount", 0),
                            myListing: my_listing,
                        },
                        () => {
                            // Update button visibility (this'll also mark as not initialising)
                            this.updateButtonVisibility();

                            // Make a call to fetch our current authed users like for this media
                            if (this.props.auth.user) {
                                Api.market({
                                    endpoint: "/content/media/like",
                                    method: "GET",
                                    data: {
                                        mediaId: this.state.mediaId,
                                    },
                                })
                                    .then((res) => {
                                        console.log("RES", res);
                                        this.setState({
                                            isLiked: res && res.hasOwnProperty(this.state.mediaId) && res[this.state.mediaId] === true,
                                        });
                                    })
                                    .catch((e) => {});
                            }

                            // Make a call to fetch the media history records
                            this.fetchHistory();

                            // Attempt to start a recursive update function so we always have the latest
                            // information
                            // setTimeout(() => {
                            //     this.recursiveListingUpdate();
                            // }, 10000);

                            // setInterval(() => {
                            //     let _history = [
                            //         ...this.state.history,
                            //         ...[
                            //             {
                            //                 mediaId: this.state.mediaId,
                            //                 type: "LISTING",
                            //                 quantity: 1,
                            //                 displayName: "SOMEONE_",
                            //                 profileImage: null,
                            //                 blockchain: "ETH",
                            //                 price: `${Math.round(Math.random() * (100 - 0.1) + 0.1)} ETH`,
                            //                 timestamp: new Date().toISOString(),
                            //                 state: "AUCTION_BID",
                            //             },
                            //         ],
                            //     ];
                            //     _history = _history.sort((a, b) => {
                            //         return a.timestamp > b.timestamp ? -1 : a.timestamp < b.timestamp ? 0 : 1;
                            //     });
                            //     _history = _history.slice(0, 5);
                            //     this.setState({
                            //         history: _history,
                            //     });
                            // }, 10000);

                            // If this is our media + minting is not setup - nudge user
                            setTimeout(() => {
                                if (this.state.content.created === true && this.state.content.mintingSetup !== true) {
                                    Util.notify.info(
                                        `Minting Setup hasn't been completed yet for this media - this must be completed before you can list for sale or mint. Click here to do this now!`,
                                        () => {
                                            navigate(`/m/mint/setup/${this.state.content.mediaId}`);
                                        }
                                    );
                                }
                            }, 1000);
                        }
                    );
                })
                .catch((e) => {
                    console.log(e);
                    this.setState({
                        initialising: false,
                        content: null,
                        likeCount: 0,
                    });
                });
        }

        refreshData = () => {
            this.componentDidMount();
        };

        updateButtonVisibility = () => {

            // Work out some stats so we know what we can display/hide
            let content = this.state.content;
            let isCreator = Common.objectPropertyExists(content, "created", false) === true;
            let isOwner = Common.objectPropertyExists(content, "owner.me", null) !== null;
            let isListed = content.listings.length > 0;
            let isListedByYou =
                content.listings.filter((list) => {
                    return Common.isDisplayNameMine(this.props.auth.user, list.owner.displayName);
                }).length > 0;
            let isListedFixedByOther =
                content.listings.filter((list) => {
                    return list.state === "FIXED_PRICE_SALE" && !Common.isDisplayNameMine(this.props.auth.user, list.owner.displayName);
                }).length > 0;
            let isListedAuctionByOther =
                content.listings.filter((list) => {
                    return list.state === "AUCTION_SALE" && !Common.isDisplayNameMine(this.props.auth.user, list.owner.displayName);
                }).length > 0;
            let isMintingSetup = content.mintingSetup === true;
            let isMintingSetupLocked = content.mintingSetupLocked === true;
            let isProcessingMint = content.owner.me && (content.owner.me.mintState === "PROCESSING_MINT" || content.owner.me.mintState === "PROCESSING_LAZY");
            let isLazyMinted = content.owner.me && content.owner.me.mintState === "LAZY_MINTED";
            let isMinted = (content.owner.me && content.owner.me.mintState === "MINTED") || content.totalMinted > 0;
            let isAvailable = content.owner.me && content.owner.me.state === "AVAILABLE";
            let isHidden = content.owner.me && content.owner.me.state === "HIDDEN";
            let isPartOfCollection = content.collection !== null;
            let ownerCount = content.owner.count;
            /*
            console.log("Zeeshan: ?? isListedByYou variable in updateButtonVisibility content.listings",isListedByYou)
            console.log("Zeeshan: ?? isCreator variable in updateButtonVisibility Common.objectPropertyExists(content, created, false) === true",isCreator)
            console.log("Zeeshan: ?? isOwner variable in updateButtonVisibility Common.objectPropertyExists(content, owner.me, null) !== null",isOwner)
            console.log("Zeeshan: ?? isAvailable variable in updateButtonVisibility content.owner.me && content.owner.me.state === AVAILABLE",isAvailable)
            console.log("Zeeshan: ?? isHidden variable in updateButtonVisibility content.owner.me && content.owner.me.state === HIDDEN",isHidden)
            console.log("Zeeshan: ?? isMintingSetup (true) variable in updateButtonVisibility content.mintingSetup",content.mintingSetup)
            console.log("Zeeshan: ?? isMintingSetupLocked (true) variable in updateButtonVisibility content.mintingSetupLocked",content.mintingSetupLocked)
            console.log("Zeeshan: ?? isProcessingMint (PROCESSING_LAZY || PROCESSING_MINT) / isLazyMinted (LAZY_MINTED) / isMinted (MINTED || content.totalMinted > 0) variable in updateButtonVisibility content.owner.me.mintState",content.owner.me.mintState)
            console.log("Zeeshan: ?? isHidden (HIDDEN) / isAvailable (AVAILABLE) variable in updateButtonVisibility content.owner.me.state",content.owner.me.state)
            */
            // Update the state
            this.setState({
                initialising: false,
                showSetupMintButton: isCreator && isOwner && !isMintingSetup && !isMintingSetupLocked,
                showMintButton: !isListedByYou && isCreator && isOwner && (isAvailable || isHidden) && isMintingSetup && !isMinted && !isLazyMinted && !isProcessingMint,
                showListButton: !isListedByYou && isOwner && !isProcessingMint && (isAvailable || isHidden) && (isMinted || (isCreator && (isMintingSetup || isLazyMinted))),
                showBuyFixedButton: isAvailable && isListedFixedByOther,
                showBidAuctionButton: isAvailable && isListedAuctionByOther,
                showEditMediaLink: isCreator && !isMinted && !isLazyMinted && !isProcessingMint && !isListed,
                showMintLink: !isListedByYou && isCreator && isOwner && (isAvailable || isHidden) && isMintingSetup && !isMinted && !isLazyMinted && !isProcessingMint,
                showRemoveFromCollectionLink: false, //isCreator && isPartOfCollection && !isMinted && !isListed,
                showAddToCollectionLink: false, //isCreator && !isPartOfCollection && !isMinted && !isListed,
                showEditMintOptionsLink: isCreator && isMintingSetup && !isMintingSetupLocked,
                showEditListingLink: isOwner && isListedByYou,
                showRefreshMetadataLink: isOwner && isMinted,
                showViewMetadataLink: Common.objectPropertyExists(content, "metadataUrl", null) !== null,
                showHideMediaLink: !isListed && isOwner && isAvailable,
                showUnhideMediaLink: !isListed && isOwner && isHidden,
                showViewOwnersLink: isMinted && ownerCount > 1,
                showDeleteLink: isCreator && !isListed && ownerCount <= 1, // You can't delete minted media from the chain, but we CAN hide it from the site. Can only delete if not sold and owned by creator only
            }, () => {
                /*console.log("Zeeshan: ?? showListButton",this.state.showListButton)
                console.log("Zeeshan: ?? showMintButton",this.state.showMintButton)
                console.log("Zeeshan: ?? showSetupMintButton",this.state.showSetupMintButton)*/
                this.forceUpdate()
            });
        };

        onViewCollectionOverlay = () => {
            this.setState({
                showCollectionOverlay: true,
            });
        };
        onViewBidOverlay = (listing) => {
            if (this.main_ref) {
                this.main_ref.current.showBidOverlay(listing);
            }
        };
        onViewReportOverlay = () => {
            this.setState({
                showReportOverlay: true,
            });
        };
        onViewOwnersOverlay = () => {
            this.setState(
                {
                    ownersFetching: true,
                    showOwnersOverlay: true,
                },
                () => {
                    Api.market({
                        endpoint: "/content/media/owner",
                        method: "GET",
                        data: {
                            mediaId: this.state.mediaId,
                        },
                    })
                        .then((res) => {
                            this.setState({
                                ownersFetching: false,
                                owners: res.data.owners,
                            });
                        })
                        .catch((e) => {
                            this.setState({
                                ownersFetching: false,
                                owners: [],
                            });
                        });
                }
            );
        };
        onDeleteOverlay = () => {
            this.setState({
                showDeleteOverlay: true,
            });
        };
        onCloseOverlay = () => {
            this.setState({
                showCollectionOverlay: false,
                showBidOverlay: false,
                bidListing: null,
                showOwnersOverlay: false,
                showReportOverlay: false,
                showDeleteOverlay: false,
            });
        };

        onLike = () => {
            const old_liked = this.state.isLiked;
            const old_like_count = this.state.likeCount;

            if (!Common.objectPropertyExists(this.props, "auth.user", null)) {
                Util.notify.info("Login in to like media!");
            } else {
                this.setState(
                    {
                        isLiked: !old_liked,
                        likeCount: old_liked ? old_like_count - 1 : old_like_count + 1,
                    },
                    () => {
                        Api.market({
                            endpoint: "/content/media/like",
                            method: "PATCH",
                            data: {
                                value: this.state.isLiked ? "LIKED" : "UNLIKED",
                                mediaId: this.state.mediaId,
                            },
                        })
                            .then((res) => {
                                this.setState({
                                    likeCount: res.likeCount,
                                });
                            })
                            .catch((e) => {
                                // Failed - undo our local changes
                                this.setState({
                                    isLiked: old_liked,
                                    likeCount: old_like_count,
                                });
                            });
                    }
                );
            }
        };

        onShareByUrl = () => {
            const url = window.location.href;
            navigator.clipboard.writeText(url);
            Util.notify.info(`Copied url ${Common.cropTo(url, 18)} to clipboard.`);
        };

        onShareByEmail = () => {
            const url = window.location.href;
            const subject = encodeURIComponent(`Check Out ${this.state.content.name} on B Minted!`);
            const message = encodeURIComponent(
                `Hi! :)\n\nI saw this and thought of you! Thought you might like to check it out; its a piece of media called '${this.state.content.name}', I found it on the new NFT B Minted marketplace! You can check it out yourself at:\n\n${url}\n`
            );
            window.location.href = `mailto:?subject=${subject}&body=${message}`;
        };

        fetchHistory = () => {
            return new Promise((resolve, reject) => {
                const fetch_quantity = 5;
                if (!this.state.historyFetching && this.state.historyHasMore) {
                    this.setState(
                        {
                            historyFetching: true,
                        },
                        () => {
                            Api.market({
                                endpoint: "/content/media/history",
                                method: "GET",
                                data: {
                                    mediaId: this.state.mediaId,
                                    startIndex: this.state.history.length,
                                    quantity: fetch_quantity,
                                },
                            })
                                .then((results) => {
                                    this.setState({
                                        history: [...this.state.history, ...results.history],
                                        historyFetching: false,
                                        historyHasMore: results.history.length === fetch_quantity,
                                    });
                                })
                                .catch((e) => {
                                    this.setState(
                                        {
                                            historyFetching: false,
                                            historyHasMore: true,
                                        },
                                        () => {
                                            Util.notify.error(`Failed to get history for the media - please try again later.`);
                                        }
                                    );
                                });
                        }
                    );
                }
            });
        };

        recursiveListingUpdate = () => {
            return new Promise((resolve, reject) => {
                Promise.all([
                    Api.exchange({
                        endpoint: "/listings/media",
                        method: "GET",
                        data: {
                            mediaId: this.state.mediaId,
                        },
                    }),
                ])
                    .then((list_res) => {
                        this.setState(
                            {
                                content: {
                                    ...this.state.content,
                                    ...list_res.data,
                                },
                            },
                            () => {
                                setTimeout(() => {
                                    this.recursiveListingUpdate();
                                }, 60000);
                            }
                        );
                    })
                    .catch((e) => {
                        Util.notify.error(`Failed to fetch latest listing information - auto refresh has stopped. Please refresh to see new information.`);
                    });
            });
        };

        onToggleFullScreen = () => {
            this.setState({
                fullscreen: !this.state.fullscreen,
            });
        };

        onLoadCollections = () => {
            if (this.props.auth.user && !this.state.hasFetchedCollections) {
                Api.market({
                    endpoint: "/content/collection/list",
                    method: "GET",
                })
                    .then((res) => {
                        this.setState({
                            hasFetchedCollections: true,
                            collectionsAddedTo: res.data.filter((collection) => {
                                return (
                                    Array.isArray(collection.content) &&
                                    collection.content.filter((i) => {
                                        return i.contentId === this.state.mediaId;
                                    }).length > 0
                                );
                            }),
                            collectionsNotAddedTo: res.data.filter((collection) => {
                                return (
                                    Array.isArray(collection.content) &&
                                    collection.content.filter((i) => {
                                        return i.contentId === this.state.mediaId;
                                    }).length <= 0
                                );
                            }),
                        });
                    })
                    .catch((e) => {
                        this.setState({
                            collectionsNotAddedTo: [],
                            collectionsAddedTo: [],
                        });
                    });
            }
        };

        onAddToCollection = (collection) => {
            if (this.props.auth.user) {
                this.setState(
                    {
                        addingToCollection: true,
                    },
                    () => {
                        Api.market({
                            endpoint: "/content/collection/content/media",
                            method: "POST",
                            data: {
                                collectionId: collection.collectionId,
                                mediaId: this.state.mediaId,
                            },
                        })
                            .then((res) => {
                                // Add to our medias collection
                                let media_content_collections = this.state.content.collections || [];
                                if (media_content_collections.indexOf(collection.collectionId) === -1) {
                                    media_content_collections.push(collection.collectionId);
                                }

                                // Update our collections
                                let collections_added_to = [...this.state.collectionsAddedTo, collection];
                                let collections_not_added_to = this.state.collectionsNotAddedTo.filter((c) => {
                                    return c.collectionId !== collection.collectionId;
                                });

                                // Remove the collection from our... collection?
                                this.setState(
                                    {
                                        addingToCollection: false,
                                        collectionsAddedTo: collections_added_to,
                                        collectionsNotAddedTo: collections_not_added_to,
                                        content: {
                                            ...this.state.content,
                                            collections: media_content_collections,
                                        },
                                    },
                                    () => {
                                        // Show success alert
                                        Util.notify.info(`Added '${this.state.content.name}' to collection '${collection.name}'`);
                                    }
                                );
                            })
                            .catch((e) => {
                                this.setState(
                                    {
                                        addingToCollection: false,
                                    },
                                    () => {
                                        Util.notify.error(`Failed to add item to your collection`);
                                    }
                                );
                            });
                    }
                );
            }
        };

        onRefreshMetadata = () => {
            if (!this.state.isRefreshingMetadata) {
                Util.notify.info("Adding media to a queue for refreshing, waiting for confirmation...");
                this.setState(
                    {
                        isRefreshingMetadata: true,
                    },
                    () => {
                        Api.market({
                            endpoint: "/chain/sync/nft/metadata",
                            method: "POST",
                            data: {
                                mediaId: this.state.mediaId,
                            },
                        })
                            .then((res) => {
                                this.setState(
                                    {
                                        isRefreshingMetadata: false,
                                    },
                                    () => {
                                        Util.notify.info("Media has been successfully added to a queue for refreshing. Please allow several minutes to see any changes.");
                                    }
                                );
                            })
                            .catch((e) => {
                                this.setState(
                                    {
                                        isRefreshingMetadata: false,
                                    },
                                    () => {
                                        Util.notify.error("We're unable to refresh this media's metadata at the moment, please try again later.");
                                    }
                                );
                            });
                    }
                );
            }
        };

        makeHidden = () => {
            this.changeState("HIDDEN");
        };
        makeVisible = () => {
            this.changeState("AVAILABLE");
        };
        changeState = (state) => {
            if (!this.state.changingState) {
                this.setState(
                    {
                        changingState: true,
                    },
                    () => {
                        Api.market({
                            endpoint: "/content/media",
                            method: "PATCH",
                            data: {
                                mediaId: this.state.mediaId,
                                state: state,
                            },
                        })
                            .then((res) => {
                                this.setState(
                                    {
                                        changingState: false,
                                        content: {
                                            ...this.state.content,
                                            state: state,
                                        },
                                    },
                                    () => {
                                        this.updateButtonVisibility();
                                        Util.notify.info(
                                            state === "HIDDEN"
                                                ? `Your media has been hidden from public view. You can unhide if you want it to be visible again.`
                                                : `Your media is now visible to everyone.`
                                        );
                                    }
                                );
                            })
                            .catch((e) => {
                                this.setState(
                                    {
                                        changingState: false,
                                    },
                                    () => {
                                        Util.notify.error(`Failed to update your medias visibility. Please try again later.`);
                                    }
                                );
                            });
                    }
                );
            }
        };

        onConfirmBidAcceptance = (id, checked) => {
            this.setState({
                hasConfirmedBidAcceptance: checked,
            });
        };

        getFiatBalance = (cryptoSymbol, cryptoBalance, fiatSymbol) => {
            console.log("getFiatBalance", cryptoSymbol, cryptoBalance, fiatSymbol, this.props.prices);
            if (Array.isArray(this.props.prices)) {
                for (let i = 0; i < this.props.prices.length; i++) {
                    if (this.props.prices[i].symbol === cryptoSymbol) {
                        let current_price = this.props.prices[i].hasOwnProperty(fiatSymbol.toLowerCase()) ? this.props.prices[i][fiatSymbol.toLowerCase()] : 0;
                        return (cryptoBalance * current_price).toFixed(2);
                    }
                }
            } else {
                return 0;
            }
        };

        refreshPage = () => {
            window.location.reload();
        };

        onAuctionCountdownReached = (listing) => {
            console.log("onAuctionCountdownReached");
            if (new Date(listing && listing.auctionEndDate) > new Date()) {
                this.refreshData();
            } else {
                Api.exchange({
                    endpoint: "/auction/conclude",
                    method: "GET",
                    data: {
                        mediaId: this.state.mediaId,
                    },
                })
                    .then(() => {
                        // We need to mutate the state of multiple objects to clear the auction panel & update the listing in the listings section
                        this.setState(
                            {
                                content: {
                                    ...this.state.content,
                                    featuredListing: {
                                        ...listing,
                                        state: "AUCTION_PENDING_COMPLETION",
                                    },
                                    listings: this.state.content.listings
                                        ? this.state.content.listings.map((existingListing) => (existingListing.id == listing.id ? { ...listing, state: "AUCTION_PENDING_COMPLETION" } : listing))
                                        : [],
                                },
                            },
                            () => {
                                console.log(this.state);
                            }
                        );
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            }
        };

        renderListingPanel = (listing) => {
            if (!listing) {
                return null;
            } else if (listing.state === "AUCTION_SALE" && new Date(listing.auctionStartDate) > new Date()) {
                return (
                    <div className={styles.list_panel}>
                        <div className={styles.column_1}>
                            <AuctionCountdown
                                text={"Auction Starting In..."}
                                toDate={listing.auctionStartDate}
                                style={{ background: "transparent" }}
                                onCountdownReached={() => {
                                    this.onAuctionCountdownReached(listing);
                                }}
                            />
                        </div>
                    </div>
                );
            } else if (listing.state === "AUCTION_SALE") {
                return (
                    <div className={styles.list_panel}>
                        <div className={styles.column_1}>
                            <div className={styles.current_container}></div>
                            <div className={styles.remaining}>
                                <p className={styles.time_remaining_text}>Auction Ends In</p>
                                <AuctionCountdown
                                    toDate={listing.auctionEndDate}
                                    style={{ padding: 0 }}
                                    onCountdownReached={() => {
                                        this.onAuctionCountdownReached(listing);
                                    }}
                                />
                                <p className={styles.quantity_remaining}>{`${listing.quantity - listing.quantitySold}/${listing.quantity} Items Remaining`}</p>
                            </div>
                        </div>
                        <div className={styles.column_2}>
                            {this.state.myListing && this.state.myListing.id === listing.id ? (
                                <Button displayMode={9} hoverMode={6} large={true} disabled={false} text={"View Listing"} to={`/listings`} style={{ margin: 0 }} />
                            ) : (
                                <Button
                                    displayMode={9}
                                    hoverMode={6}
                                    large={true}
                                    disabled={false}
                                    text={"Place Bid"}
                                    onClick={() => {
                                        this.onViewBidOverlay(listing);
                                    }}
                                    style={{ margin: 0 }}
                                />
                            )}
                        </div>
                    </div>
                );
            } else if (listing.state === "FIXED_PRICE_SALE") {
                return (
                    <div className={styles.list_panel}>
                        <div className={styles.column_1}>
                            <div className={styles.current_container}></div>
                            <div className={styles.remaining}>
                                <p className={styles.price_text}>
                                    <span className={styles.price_fiat_text}>{`$${this.getFiatBalance(
                                        this.state.content.featuredListing.fixedPriceCoin,
                                        Web3Utils.fromWei(`${this.state.content.featuredListing.fixedPrice}`, "ether"),
                                        "usd"
                                    )}`}</span>
                                    {Common.currencyFormat(this.state.content.featuredListing.fixedPriceCoin, Web3Utils.fromWei(`${this.state.content.featuredListing.fixedPrice}`, "ether"))}
                                </p>
                                <p className={styles.quantity_remaining}>{`${listing.quantity - listing.quantitySold}/${listing.quantity} Items Remaining`}</p>
                            </div>
                        </div>
                        <div className={styles.column_2}>
                            {this.state.myListing && this.state.myListing.id === listing.id ? (
                                <Button displayMode={9} hoverMode={6} large={true} disabled={false} text={"View Listing"} to={`/listings`} style={{ margin: 0 }} />
                            ) : (
                                <Button
                                    displayMode={9}
                                    hoverMode={6}
                                    large={true}
                                    disabled={false}
                                    text={"Buy Now"}
                                    onClick={() => {
                                        this.onViewBidOverlay(this.state.content.featuredListing);
                                    }}
                                    style={{ margin: 0 }}
                                />
                            )}
                        </div>
                    </div>
                );
            }
        };

        onDeleteMedia = () => {
            this.setState(
                {
                    deleting: true,
                },
                () => {
                    Provider.SignMessageWithAccountWallet(this.props.auth.user.addresses)
                        .then((signature) => {
                            Api.market({
                                endpoint: "/content/media",
                                method: "DELETE",
                                data: {
                                    mediaId: this.state.mediaId,
                                    signature: signature,
                                },
                            })
                                .then(() => {
                                    Util.notify.info(`Media deleted.`);
                                    const bminted_profile = this.props.auth.user.profiles.filter((p) => p.scheme === "BMINTED");
                                    if (bminted_profile.length > 0 && bminted_profile[0].displayName) {
                                        navigate(`/u/${bminted_profile[0].displayName}`);
                                    } else {
                                        navigate("/");
                                    }
                                })
                                .catch((e) => {
                                    console.error(e);
                                    this.setState(
                                        {
                                            deleting: false,
                                        },
                                        () => {
                                            Util.notify.error(`Failed to delete your media. Please retry or try again later.`);
                                        }
                                    );
                                });
                        })
                        .catch((e) => {
                            console.error(e);
                            this.setState(
                                {
                                    deleting: false,
                                },
                                () => {
                                    Util.notify.error(`Failed to delete your media. Please retry or try again later.`);
                                }
                            );
                        })
                        .finally(() => {
                            this.onCloseOverlay();
                        });
                }
            );
        };

        onConfirmDelete = (id, val) => {
            this.setState({
                hasConfirmedDelete: val,
            });
        };

        render() {
            return !this.state.content ? (
                <Main
                    title={"BMinted"}
                    initialising={this.state.initialising}
                    auth={this.props.auth}
                    prices={this.props.prices}
                    providers={this.props.providers}
                    currentChain={this.props.currentChain}
                    chains={this.props.chains}
                >
                    <NotFoundPage notFoundName={"Media"} />
                </Main>
            ) : this.state.fullscreen === true ? (
                <main>
                    <div className={styles.fullscreen_container} onClick={this.onToggleFullScreen}>
                        {this.state.content && <img src={`${process.env.GATSBY_STORAGE}media/${this.state.content.displayImage}`} />}
                    </div>
                </main>
            ) : (
                <Main
                    ref={this.main_ref}
                    initialising={this.state.initialising}
                    title={"BMinted"}
                    auth={this.props.auth}
                    prices={this.props.prices}
                    providers={this.props.providers}
                    noFooterMargin={true}
                    currentChain={this.props.currentChain}
                    chains={this.props.chains}
                >
                    <ContentArea extraTopPadding={true}>
                        <div className={styles.content_container}>
                            <div className={`${styles.column} ${styles.column_fill} ${styles.column_minimum}`}>
                                <div className={styles.content_wrapper}>
                                    {this.state.content.type === "AUDIO" ? (
                                        <div className={styles.audio_container}>
                                            {this.state.content.displayImage && <img src={`${process.env.GATSBY_STORAGE}media/${this.state.content.displayImage}`} />}
                                            <audio controls controlsList="nodownload" loop={true} autoPlay={false} preload="true" src={`${process.env.GATSBY_STORAGE}media/${this.state.content.data}`}>
                                                Your browser does not support the
                                                <code>audio</code> element.
                                            </audio>
                                        </div>
                                    ) : this.state.content.type === "VIDEO" ? (
                                        <video controls autoPlay={true} muted={true} controlsList="nodownload nofullscreen noremoteplayback noplaybackrate" loop={true} preload="true">
                                            <source src={`${process.env.GATSBY_STORAGE}media/${this.state.content.data}`} type="video/mp4" />
                                            Sorry, your browser doesn't support embedded videos.
                                        </video>
                                    ) : (this.state.content.displayImage && (
                                            <img src={`${process.env.GATSBY_STORAGE}media/${this.state.content.displayImage}`} onClick={this.onToggleFullScreen} onError={this.onImgError} />
                                        //     <video controls autoPlay={true} muted={true} controlsList="nodownload nofullscreen noremoteplayback noplaybackrate" loop={true} preload="true">
                                        //     <source src={`${this.state.content.data}`} type="video/webm" />
                                        //     Sorry, your browser doesn't support embedded videos.
                                        // </video>
                                        ))}
                                </div>
                                <div className={styles.social_container}>
                                    <div className={styles.social_likes_container}>
                                        {/* <SocialButton name={"Share on Facebook"} onClick={this.onShareFacebook} icon={faFacebookF} /> */}
                                        <SocialButton name={"Share by Email"} onClick={this.onShareByEmail} icon={faEnvelope} />
                                        <SocialButton name={"Copy Media Address"} onClick={this.onShareByUrl} icon={faLink} />
                                        <div className={styles.social_spacing} />
                                        <FontAwesomeIcon title="Like" icon={this.state.isLiked ? faHeartSolid : faHeart} className={styles.social_icon} onClick={this.onLike} />
                                        <span className={styles.social_likes_count}>{Common.numberFormat(this.state.likeCount)} Likes</span>
                                    </div>
                                    <br />
                                    <br />
                                </div>
                                <TextArea header={"Details"}>
                                    <p>
                                        Created: <b>&nbsp;&nbsp;{`${Common.dateString(this.state.content.createdAt)}`}</b>
                                        <br />
                                        Hidden Content: <b>&nbsp;&nbsp;{`${this.state.content.hiddenContent === false ? "No" : "Yes"}`}</b>
                                        <br />
                                        Contract Address:{" "}
                                        <b>
                                            &nbsp;&nbsp;
                                            <a className={styles.link} target={"_blank"} href={`https://etherscan.io/address/${this.state.content.tokenAddress}`}>
                                                {Common.cropTo(this.state.content.tokenAddress, 15)}
                                            </a>
                                        </b>
                                        <br />
                                        Token ID: <b>&nbsp;&nbsp;{Common.cropTo(this.state.content.tokenId || "-", 15)}</b>
                                        <br />
                                        Token Standard: <b>&nbsp;&nbsp;{this.state.content.tokenStandard || "-"}</b>
                                    </p>
                                </TextArea>

                                {this.state.content.attributes && this.state.content.attributes.length > 0 && (
                                    <TextArea header={"Attributes"}>
                                        <div className={styles.attribute_container}>
                                            {this.state.content.attributes.map((a, i) => (
                                                <MediaAttribute key={`media_attribute_${i}`} name={a.name} value={a.value} />
                                            ))}
                                        </div>
                                    </TextArea>
                                )}
                            </div>
                            <div className={`${styles.column} ${styles.column_constrain}`}>
                                <div className={styles.text_content}>
                                    <TextArea>
                                        <h1 className={`${styles.mainTitle}`}>{this.state.content.name}</h1>
                                    </TextArea>
                                    <TextArea>
                                        <p>{this.state.content.description.en}</p>
                                    </TextArea>
                                    {this.state.content.tags.length > 0 && (
                                        <TextArea>
                                            <p>
                                                {this.state.content.tags.map((tag, index) => (
                                                    <SearchLink key={`tag_${index}`} term={tag} />
                                                ))}
                                            </p>
                                        </TextArea>
                                    )}
                                    {this.state.content.collection && (
                                        <ValueRow
                                            title={<CollectionName id={this.state.content.collection.collectionId} name={this.state.content.collection.name} />}
                                            icon={this.state.content.collection.displayImage ? `${process.env.GATSBY_STORAGE}collection/${this.state.content.collection.displayImage}` : null}
                                            value={"Collection"}
                                            border={false}
                                        />
                                    )}
                                    {this.state.content.creator.displayName && (
                                        <ValueRow
                                            title={<DisplayName scheme={this.state.content.scheme}>{`${this.state.content.creator.displayName}`}</DisplayName>}
                                            icon={this.state.content.creator.profileImage ? `${process.env.GATSBY_STORAGE}user/${this.state.content.creator.profileImage}` : null}
                                            value={"Content Creator"}
                                            border={false}
                                        />
                                    )}
                                </div>
                                <div className={styles.text_section}>
                                    <p>
                                        Owners:{" "}
                                        <b>
                                            &nbsp;&nbsp;
                                            {this.state.content.owner.count > 1
                                                ? `${this.state.content.owner.count} owners`
                                                : this.state.content.owner.me
                                                ? `Owned by you`
                                                : this.state.content.owner.owners && this.state.content.owner.owners.length > 0
                                                ? `Owned by ${this.state.content.owner.owners[0].displayName}`
                                                : `1 owner`}
                                        </b>
                                        <br />
                                        Creator Royalties: <b>&nbsp;&nbsp;{`${this.state.content.royaltiesPercent === null ? "-" : this.state.content.royaltiesPercent + "%"}`}</b>
                                        <br />
                                        Total Supply: <b>&nbsp;&nbsp;{this.state.content.totalSupply || "-"}</b>
                                    </p>
                                    {(this.state.showSetupMintButton || this.state.showMintButton || this.state.showListButton) && ( // || this.state.showBuyFixedButton || this.state.showBidAuctionButton
                                        <div className={styles.flex_row}>
                                            {this.state.showSetupMintButton && (
                                                <Button displayMode={5} hoverMode={6} large={true} text={"Setup Minting"} to={`/m/mint/setup/${this.state.content.mediaId}`} noMargin={true} />
                                            )}
                                            {this.state.showMintButton && (
                                                <Button displayMode={5} hoverMode={6} large={true} text={"Mint"} to={`/m/mint/${this.state.content.mediaId}`} noMargin={true} />
                                            )}
                                            {this.state.showListButton && (
                                                <Button displayMode={5} hoverMode={6} large={true} text={"List For Sale"} to={`/m/list/${this.state.content.mediaId}`} noMargin={true} />
                                            )}
                                        </div>
                                    )}
                                </div>
                                {"me" in this.state.content.owner && (
                                    <div className={styles.text_section}>
                                        {this.state.content.owner.me && this.state.content.owner.me.mintState === "PROCESSING_LAZY" ? (
                                            <p>
                                                <b>Processing Lazy Mint</b>
                                                <br />
                                                We are currently waiting for you to sign a voucher we supplied you. Click 'Complete Lazy Mint' below to finish the process or 'Cancel Lazy Mint' to
                                                cancel.
                                            </p>
                                        ) : this.state.content.owner.me && this.state.content.owner.me.mintState === "PROCESSING_MINT" ? (
                                            <p>
                                                <b>Processing Mint</b>
                                                <br />
                                                We are waiting to hear back from the blockchain to indicate a successful mint. This can sometimes take a while depending on network traffic so please be
                                                patient and keep checking back.
                                            </p>
                                        ) : this.state.myListing && this.state.myListing.state === "FIXED_PRICE_SALE" ? (
                                            <p>
                                                <b>Media For Sale</b>
                                                <br />
                                                You are currently selling {this.state.myListing.quantity} of your owned items for a fixed price of{" "}
                                                {Common.currencyFormat(this.state.myListing.fixedPriceCoin, Web3Utils.fromWei(`${this.state.myListing.fixedPrice}`, "ether"))} ($
                                                {this.getFiatBalance(this.state.myListing.fixedPriceCoin, Web3Utils.fromWei(`${this.state.myListing.fixedPrice}`, "ether"), "usd")}).
                                            </p>
                                        ) : this.state.myListing && this.state.myListing.state === "AUCTION_SALE" ? (
                                            <p>
                                                <b>Media For Sale</b>
                                                <br />
                                                {new Date(this.state.myListing.auctionStartDate) > new Date()
                                                    ? `You are going to be selling ${this.state.myListing.quantity} of your owned items in an auction which is set to start ${Common.dateString(
                                                          this.state.myListing.auctionStartDate
                                                      )}.`
                                                    : `You are currently selling ${this.state.myListing.quantity} of your owned items in an auction which is set to end ${Common.dateString(
                                                          this.state.myListing.auctionEndDate
                                                      )}. You can stop the auction at anytime but any above reserve bids must be honored.`}
                                            </p>
                                        ) : this.state.myListing && this.state.myListing.state === "AUCTION_PENDING_COMPLETION" ? (
                                            <p>
                                                <b>Auction Pending Completion</b>
                                                <br />
                                                The auction sale is currently pending completion. This means the auction has ended and we are waiting on auction winners to claim their winnings. Until
                                                the auction has been completed you will be unable to further list any remaining of this media you own.
                                            </p>
                                        ) : this.state.content.owner.me && this.state.content.owner.me.state === "PENDING_VERIFICATION" ? (
                                            <p>
                                                <b>Pending Verification</b>
                                                <br />
                                                This media is currently pending verification - You are the only user who can view this media until it has been verified. Until the media has been
                                                verified you will not be able to mint or sell, you can however setup/change minting options in preperation.
                                            </p>
                                        ) : this.state.content.owner.me && this.state.content.owner.me.state === "HIDDEN" ? (
                                            <p>
                                                <b>Media Hidden</b>
                                                <br />
                                                This media is hidden, no one (other than yourself) can see this media on your profile. You can 'unhide' to make it publicly visible on your profile.
                                            </p>
                                        ) : (
                                            this.state.content.owner.me &&
                                            this.state.content.owner.me.state === "AVAILABLE" && (
                                                <p>
                                                    <b>Publicly Visible</b>
                                                    <br />
                                                    This media can currently be viewed publicly on your profile, you can 'hide' to remove visibility.
                                                </p>
                                            )
                                        )}
                                    </div>
                                )}
                                <div className={styles.text_section}>
                                    {this.state.showEditMediaLink && <LinkWithIcon icon={faPencilAlt} text={"Edit Media"} to={`/m/edit/${this.state.content.mediaId}`} />}
                                    {/* {this.state.showMintLink && <LinkWithIcon icon={faGears} text={"Mint"} to={`/m/mint/${this.state.content.mediaId}`} />} */}
                                    {this.state.showEditMintOptionsLink && <LinkWithIcon icon={faPencilAlt} text={"Edit Minting Options"} to={`/m/mint/setup/${this.state.content.mediaId}`} />}
                                    {this.state.showEditListingLink && <LinkWithIcon icon={faPencilAlt} text={"Edit Listing"} to={`/m/list/${this.state.content.mediaId}`} />}
                                    {this.state.showAddToCollectionLink && <LinkWithIcon icon={faPlus} text={"Add To Collection"} onClick={this.onViewCollectionOverlay} />}
                                    {this.state.showRemoveFromCollectionLink && <LinkWithIcon icon={faMinus} text={"Remove From Collection"} onClick={this.onViewCollectionOverlay} />}
                                    {this.state.showHideMediaLink && <LinkWithIcon icon={faEye} text={"Hide on Profile"} onClick={this.makeHidden} />}
                                    {this.state.showUnhideMediaLink && <LinkWithIcon icon={faEyeSlash} text={"Show on Profile"} onClick={this.makeVisible} />}
                                    {this.state.showViewOwnersLink && <LinkWithIcon icon={faUser} text={"View Owners"} onClick={this.onViewOwnersOverlay} />}
                                    {this.state.showRefreshMetadataLink && <LinkWithIcon icon={faSync} text={"Refresh Metadata"} onClick={this.onRefreshMetadata} />}
                                    {this.state.showViewMetadataLink && <LinkWithIcon icon={faArrowUpRightFromSquare} text={"View Metadata"} openNewTab={true} to={this.state.content.metadataUrl} />}
                                    {this.state.showDeleteLink && <LinkWithIcon icon={faTrash} text={"Delete media"} onClick={this.onDeleteOverlay} />}
                                </div>
                            </div>
                        </div>
                    </ContentArea>
                    {this.state.content.listings.filter((listing) => listing.state != "AUCTION_PENDING_COMPLETION").length > 0 && (
                        <ContentArea header={"Listings"} slim={true}>
                            <div className={styles.list_container}>
                                {this.state.content.listings
                                    .filter((listing) => listing.state != "AUCTION_PENDING_COMPLETION")
                                    .map((listing, index) => (
                                        <ListingItem key={`listing_item_${index}`} item={listing} onBid={this.onViewBidOverlay} />
                                    ))}
                            </div>
                        </ContentArea>
                    )}
                    {this.state.history.length > 0 && (
                        <ContentArea header={"History"} slim={true}>
                            <div className={styles.list_container}>
                                {this.state.history.map((item, index) => (
                                    <MediaHistoryItem key={`history_item_${index}`} item={item} />
                                ))}
                                {this.state.historyHasMore && (
                                    <div className={styles.list_button}>
                                        <Button displayMode={5} hoverMode={6} text={"More History"} onClick={this.fetchHistory} noMargin={true} />
                                    </div>
                                )}
                            </div>
                        </ContentArea>
                    )}
                    {this.state.showOwnersOverlay && (
                        <OverlayContainer header={"Owners"} onClose={this.onCloseOverlay}>
                            {this.state.ownersFetching ? (
                                <OverlayInfo noPadding={true}>
                                    <ScreenLoad />
                                </OverlayInfo>
                            ) : this.state.owners.length <= 0 ? (
                                <OverlayInfo header={<span>Oh No! &#x1F631;</span>} message={`This media doesn't appear to have any owners. If its up for sale maybe you should buy it?`} />
                            ) : (
                                <div className={styles.owner_list}>
                                    {this.state.owners.map((o) => (
                                        <ValueRow avatar={o.owner} title={<DisplayName>{o.owner.displayName}</DisplayName>} value={`Owns ${o.quantity}`} transparent={true} />
                                    ))}
                                </div>
                            )}
                        </OverlayContainer>
                    )}
                    {this.state.showDeleteOverlay && (
                        <OverlayContainer header={"Delete Media"} onClose={this.onCloseOverlay}>
                            <div className={styles.delete}>
                                <p>
                                    Deleting media is permenant and <u>cannot be undone</u>. Tap the checkbox below to confirm your understand and to allow you to delete.
                                </p>
                                <Checkbox checked={this.state.hasConfirmedDelete} onCheckChanged={this.onConfirmDelete} text={"Please delete this media."} />
                                <Button
                                    displayMode={5}
                                    hoverMode={6}
                                    disabled={!this.state.hasConfirmedDelete}
                                    text={this.state.deleting ? "Deleting..." : "Delete this Media"}
                                    onClick={this.onDeleteMedia}
                                    style={{ margin: 0, marginTop: 15 }}
                                />
                            </div>
                        </OverlayContainer>
                    )}
                    {this.state.showReportOverlay && <ReportOverlay contentType={"MEDIA"} contentId={this.props.params.media_id} onClose={this.onCloseOverlay} />}
                    {this.state.showCollectionOverlay && (
                        <OverlayContainer onClose={this.onCloseOverlay}>
                            {/* {
                            !this.state.hasFetchedCollections
                            ? <p>
                                Please Wait<br /><b>Fetching Your Existing Collections...</b></p>
                            : this.state.collectionsNotAddedTo.length === 0 && this.state.collectionsAddedTo.length === 0
                            ? [
                                  {
                                      type: "link",
                                      name: "No Collections Found!",
                                      description: "Create a collection and start adding your favourite media to it!",
                                      to: `/c/create?mediaId=${this.state.mediaId}`,
                                  },
                              ]
                            : [
                                  this.state.collectionsAddedTo.length > 0 && {
                                      type: "section",
                                      content: (
                                          <div className={styles.collections_added_container}>
                                              <h5>ADDED TO...</h5>
                                              <div className={styles.collections_added_inner}>
                                                  {this.state.collectionsAddedTo.map((c, index) => (
                                                      <Link
                                                          key={`added_to_collection_${index}`}
                                                          title={c.name}
                                                          className={styles.collection_icon}
                                                          to={`/c/${c.collectionId}`}
                                                      >
                                                          {c.displayImage && <img src={`${process.env.GATSBY_STORAGE}collection/${c.displayImage}`} />}
                                                      </Link>
                                                  ))}
                                              </div>
                                          </div>
                                      ),
                                  },
                                  {
                                      type: "link",
                                      name: "Add to a New Collection",
                                      description: "Click here to create a new Collection with this media",
                                      to: `/c/create?mediaId=${this.state.mediaId}`,
                                  },
                                  ...this.state.collectionsNotAddedTo.map((collection) => {
                                      return {
                                          type: "item",
                                          name: collection.name,
                                          onClick: () => {
                                              this.onAddToCollection(collection);
                                          },
                                      };
                                  }),
                              ]
                        } */}
                        </OverlayContainer>
                    )}

                    <br />
                    <br />
                    <br />
                    {this.state.myListing ? this.renderListingPanel(this.state.myListing) : this.renderListingPanel(this.state.content.featuredListing)}
                    {/* <div>
                    <DatePicker
                        ref={this.auctionStartDateRef}
                        selected={this.state.auctionStartDateType === "SPECIFIC_DATE" && this.state.auctionStartDate ? this.state.auctionStartDate : new Date()}
                        onChange={this.auctionStartDateChanged}
                        customInput={<div style={{ height: 40, background: "gray", width:"100%" }} />}
                        showTimeInput={true}
                        dateFormat="dd/MM/yyyy h:mm aa"
                        minDate={new Date()}
                        maxDate={new Date().setFullYear(new Date().getFullYear() + 1)}
                        withPortal={true}
                        highlightDates={this.state.auctionStartDateType === "SPECIFIC_DATE" && this.state.auctionStartDate ? this.state.auctionStartDate : new Date()}
                        disabledKeyboardNavigation
                    />
                    </div> */}
                </Main>
            );
        }
    }
);
