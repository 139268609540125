import React from "react";
import { Link } from "gatsby";
import * as styles from "./mediaHistoryItem.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DisplayName from "./displayname";
import Avatar from "./avatar";
import Common from "../config/common";

export default class MediaHistoryItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    typeToDescription = () => {
        if (this.props.item && this.props.item.type) {
            switch (this.props.item.type) {
                case "CREATED":
                    return `created the media`;
                case "VERIFIED":
                    return `verified the media`;
                case "ADDED":
                    return `added media to profile`;
                case "ADDED_TO_COLLECTION":
                    return `added media to a collection`;
                case "RECEIVED":
                    return `received the media`;
                case "MINTING_SETUP":
                    return `prepared media for minting`;
                case "MINTING_SETUP_CHANGED":
                    return `updated the minting options`;
                case "MINTED":
                    return `minted the media`;
                case "LISTING":
                    switch (this.props.item.state) {
                        case "NOT_FOR_SALE":
                        case "REMOVED_FROM_SALE":
                            return `removed media from sale`;
                        case "FIXED_PRICE_SALE":
                            return `listed the media for buy now sale ${this.props.item.price}`;
                        case "AUCTION_SALE":
                            return `listed the media for auction sale`;
                        case "SOLD":
                            return `sold the media for ${Common.currencyFormat("ETH", this.props.item.price)}`;
                        case "GIFTED":
                            return `gifted the media`;
                        default:
                            return `changed the listing state`;
                    }
                case "TRANSFERRED":
                    return `transferred the media`;
            }
        } else {
            return `unknown action`;
        }
    };

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.image_container}>
                    <div className={styles.image_inner}>
                        {this.props.item.displayName === "BMinted" ? (
                            <Avatar user={{ profileImage: this.props.item.fromProfileImage }} verified={true} noAnimation={true} />
                        ) : (
                            <Avatar user={{ profileImage: this.props.item.fromProfileImage }} noAnimation={true} />
                        )}
                    </div>
                </div>
                <div className={styles.text_container}>
                    <p>
                        <span className={styles.title}>
                            {this.props.item.fromDisplayName ? (
                                <DisplayName displayOnly={this.props.item.fromDisplayName === "BMinted"}>{this.props.item.fromDisplayName}</DisplayName>
                            ) : (
                                <DisplayName displayOnly={this.props.item.toDisplayName === "BMinted"}>{this.props.item.toDisplayName}</DisplayName>
                            )}
                        </span>
                    </p>
                    <p>
                        <span>&nbsp;{this.typeToDescription()}&nbsp;</span>
                        <span className={styles.timestamp} title={Common.dateString(this.props.item.timestamp)}>
                            {Common.dateAgo(this.props.item.timestamp)}
                        </span>
                    </p>
                </div>
            </div>
        );
    }
}
