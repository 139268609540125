import React from "react";
import Web3Utils from "web3-utils";
import { Link } from "gatsby";
import * as styles from "./listingItem.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DisplayName from "./displayname";
import Avatar from "./avatar";
import Button from "./button";
import Common from "../config/common";

export default class ListingItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    bid = () => {
        this.props.onBid(this.props.item);
    };

    render() {
        if (this.props.item.state !== "AUCTION_SALE" && this.props.item.state !== "FIXED_PRICE_SALE") {
            return null;
        }
        return (
            <div className={styles.container}>
                <div className={styles.image_container}>
                    <div className={styles.image_inner}>
                        <Avatar user={this.props.item.owner} noAnimation={true} />
                    </div>
                </div>
                <div className={styles.text_container}>
                    <div className={styles.line_1}>
                        {this.props.item.state === "AUCTION_SALE" ? (
                            <p>
                                {new Date(this.props.item.auctionStartDate) > new Date()
                                    ? `Auction starts ${Common.dateString(this.props.item.auctionStartDate)}`
                                    : `Auction ends ${Common.dateString(this.props.item.auctionEndDate)}`}
                            </p>
                        ) : (
                            this.props.item.state === "FIXED_PRICE_SALE" && (
                                <p>{`Fixed price sale ${Web3Utils.fromWei(`${this.props.item.fixedPrice}`, "ether")}${this.props.item.fixedPriceCoin} ($0.00)`}</p>
                            )
                        )}
                    </div>
                    <div className={styles.line_2}>
                        <span className={styles.owner_name}>
                            <DisplayName>{this.props.item.owner.displayName}</DisplayName>
                        </span>
                    </div>
                </div>
                <div className={styles.quantity_container}>
                    <p>
                        {this.props.item.quantity - this.props.item.quantitySold} / {this.props.item.quantity}
                    </p>
                </div>
                <div className={styles.button_container}>
                    {this.props.item.state === "AUCTION_SALE" ? (
                        <Button displayMode={5} hoverMode={6} text={"Bid"} onClick={this.bid} disabled={new Date(this.props.item.auctionStartDate) > new Date()} noMargin={true} />
                    ) : (
                        <Button displayMode={5} hoverMode={6} text={"Buy"} onClick={this.bid} noMargin={true} />
                    )}
                </div>
            </div>
        );
    }
}
