import React from "react";
import { Link } from "gatsby";
import * as styles from "./mediaAttribute.module.css";
import Common from "../config/common";

export default class MediaAttribute extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    render() {
        if (!this.props.name || !this.props.value) {
            return null;
        } else {
            return (
                <div className={`${styles.container} ${this.props.rarity ? styles.clickable : ""}`}>
                    <p className={styles.name}>{Common.cropTo(this.props.name, 50)}</p>
                    <p className={styles.value}>{Common.cropTo(this.props.value, 50)}</p>
                    {this.props.rarity && <p className={styles.rarity}>{`${this.props.rarity}% Rarity`}</p>}
                </div>
            );
        }
    }
}
