// extracted by mini-css-extract-plugin
export var action_content = "index-module--action_content--VOjV-";
export var action_note = "index-module--action_note--ZN+Zy";
export var attribute_container = "index-module--attribute_container--544cd";
export var audio_container = "index-module--audio_container--fd3a+";
export var avatar_container = "index-module--avatar_container--EoRQ9";
export var column = "index-module--column--wHng1";
export var column_1 = "index-module--column_1--Uz7yZ";
export var column_2 = "index-module--column_2--cuoMH";
export var column_constrain = "index-module--column_constrain--UYESV";
export var column_fill = "index-module--column_fill--xICqW";
export var column_minimum = "index-module--column_minimum---yJoV";
export var content_container = "index-module--content_container--jrJZy";
export var content_wrapper = "index-module--content_wrapper--P0WqB";
export var current_container = "index-module--current_container--tNxkN";
export var flex_row = "index-module--flex_row--DJtKU";
export var fullscreen_container = "index-module--fullscreen_container--o6NfV";
export var highest_bidder = "index-module--highest_bidder--CQwvW";
export var link = "index-module--link--q-cjP";
export var list_button = "index-module--list_button--dIX6G";
export var list_container = "index-module--list_container--RolHP";
export var list_panel = "index-module--list_panel--Ru1hI";
export var mainTitle = "index-module--mainTitle--tZoYk";
export var no_items = "index-module--no_items--N0MiA";
export var no_items_header = "index-module--no_items_header--WVfO4";
export var no_items_message = "index-module--no_items_message--0jDGe";
export var overlay_input_row = "index-module--overlay_input_row--AqbyW";
export var owner_list = "index-module--owner_list--wT9eY";
export var price_crypto = "index-module--price_crypto--Q-3YA";
export var price_fiat = "index-module--price_fiat--M9JXl";
export var price_fiat_text = "index-module--price_fiat_text--JVZt2";
export var price_text = "index-module--price_text--p3uy3";
export var quantity_remaining = "index-module--quantity_remaining--Hx--x";
export var remaining = "index-module--remaining--8T9HX";
export var social_container = "index-module--social_container--LZpqM";
export var social_icon = "index-module--social_icon--DnN0I";
export var social_likes_container = "index-module--social_likes_container--wy16M";
export var social_likes_count = "index-module--social_likes_count--ATuZL";
export var social_other_container = "index-module--social_other_container--Vv660";
export var social_spacing = "index-module--social_spacing--DsaFZ";
export var text_content = "index-module--text_content--sFjsq";
export var text_section = "index-module--text_section--IliWy";
export var time_remaining_text = "index-module--time_remaining_text--CBJMc";
export var user_links_share = "index-module--user_links_share--tjXSb";