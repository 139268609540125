import React from "react";
import { Link } from "gatsby";
import * as styles from "./auctionCountdown.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class AuctionCountdown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toDate: new Date(props.toDate),
            hasRanCalculator: false,
            tooDistant: false,
            remainingDays: null,
            remainingHours: null,
            remainingMinutes: null,
            remainingSeconds: null,
            interval: null,
        };
    }

    componentDidMount() {
        this.startInterval();
    }
    // This fires several times (along with componentDidMount) even with the props check- we keep ending up with at least 2 instances of this class running.
    // Refactor to hold the interval in State, allowing us to ensure it only ever runs once
    componentDidUpdate(prevProps, prevState, snapshot) {
        // Specifically check for the toDate to a.) not be null and b.) be different to what it was before.
        // Only then do we start a (new) interval
        if (this.props && this.props.toDate && this.props.toDate != prevProps.toDate) {
            this.startInterval();
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    startInterval = () => {
        console.log("Initialising new interval");
        const interval = setInterval(this.calculateRemaining, 1000);
        this.setState({
            toDate: new Date(this.props.toDate),
            interval,
        });
    };

    calculateRemaining = () => {
        if (this.state.toDate) {
            // Work out the remaining
            let ms_day = 1000 * 60 * 60 * 24;
            let ms_hour = 1000 * 60 * 60;
            let ms_minute = 1000 * 60;
            let ms_second = 1000;
            let end_ms = this.state.toDate.getTime();
            let now = new Date().getTime();
            let difference = Math.max(0, end_ms - now);
            let days = Math.max(0, Math.floor(difference / ms_day));
            let hours = Math.max(0, Math.floor((difference - ms_day * days) / ms_hour));
            let minutes = Math.max(0, Math.floor((difference - ms_day * days - ms_hour * hours) / ms_minute));
            let seconds = Math.max(0, Math.floor((difference - ms_day * days - ms_hour * hours - ms_minute * minutes) / ms_second));

            // Calculate the new time
            this.setState(
                {
                    runningCalculator: difference > 0,
                    hasRanCalculator: true,
                    tooDistant: days > 30,
                    remainingDays: ("00" + days).slice(-2),
                    remainingHours: ("00" + hours).slice(-2),
                    remainingMinutes: ("00" + minutes).slice(-2),
                    remainingSeconds: ("00" + seconds).slice(-2),
                },
                () => {
                    if (difference <= 0) {
                        console.log("onCountdownReached, clearing interval");
                        clearInterval(this.state.interval);
                        this.setState({ interval: null }, () => {
                            this.props.onCountdownReached && this.props.onCountdownReached();
                        });
                    }
                }
            );
        }
    };

    render() {
        if (!this.state.toDate || !this.state.hasRanCalculator || this.state.tooDistant) {
            return null;
        } else {
            return (
                <div className={styles.container} style={this.props.style}>
                    <div className={styles.inner_container}>
                        {this.props.text && <p className={styles.description}>{this.props.text}</p>}
                        <p>{`${this.state.remainingDays} : ${this.state.remainingHours} : ${this.state.remainingMinutes} : ${this.state.remainingSeconds}`}</p>
                    </div>
                </div>
            );
        }
    }
}
